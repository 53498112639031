<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <card class="form-horizontal">
        <h4 slot="header" class="card-title">New User</h4>

        <div class="row">
          <div class="col-sm-12">
            <div v-if="error==true"
              class="alert alert-alert alert-danger alert-dismissible fade show"
            >
              <span>{{ errorMessage }}</span>
            </div>
            <ValidationProvider
              name="required"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <fg-input
                type="text"
                :error="failed ? 'The Text field is required' : null"
                :hasSuccess="passed"
                name="username"
                placeholder="Name"
                v-model="username"
              >
              </fg-input>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ passed, failed }"
            >
              <fg-input
                type="text"
                :error="failed ? 'The Email field is required' : null"
                :hasSuccess="passed"
                name="email"
                v-model="email"
                placeholder="E-mail"
              >
              </fg-input>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider
              name="number"
              rules="required|numeric|min:10"
              v-slot="{ passed, failed }"
            >
              <fg-input
                type="number"
                :error="failed ? 'Please enter valid mobile number' : null"
                :hasSuccess="passed"
                name="number"
                v-model="mobile"
                placeholder="Mobile"
              >
              </fg-input>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider
              name="equal"
              rules="confirmed:confirmation"
              v-slot="{ passed, failed }"
            >
              <fg-input
                type="password"
                :error="
                  failed ? 'The confirmation password does not match' : null
                "
                :hasSuccess="passed"
                name="password"
                placeholder="New Password"
                v-model="password"
              >
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider
              name="equalTo"
              vid="confirmation"
              v-slot="{ passed, failed }"
            >
              <fg-input
                type="password"
                name="confirmpassword"
                placeholder="Confirm Passed"
                v-model="confirmpassword"
              >
              </fg-input>
            </ValidationProvider>
          </div>
        </div>

        <div slot="footer" class="text-center">
          <p-button type="info" native-type="submit" @click="sendOTP">SinUp</p-button>
        </div>

      </card>
    </form>
  </ValidationObserver>
</template>
  <script>
import { thresholdScott } from "d3";
import { Card } from "src/components/UIComponents";
import { extend } from "vee-validate";
import { required, numeric, regex, confirmed } from "vee-validate/dist/rules";

extend("required", required);
extend("numeric", numeric);
extend("regex", regex);
extend("confirmed", confirmed);

export default {
  components: {
    Card,
  },
  props:{
    formStatus:0
  },

  data() {
    return {
      username: "",
      email: "test2@gmail.com",
      mobile: 9208176642,
      password: "123456",
      confirmpassword: "123456",
      country: "",
      error:false,
      errorMessage:'',
      otp:''
    };
  },
  methods: {
    sendOTP(){
      this.$http.post('user/request_email_otp')
      .then(response => {
        this.otp = response.body.data
      })
    },
    submit() {
      this.$http
        .post("user/signup", {
          user_mobile: this.mobile,
          user_name: this.username,
          user_email: this.email,
          user_password: this.password,
          user_country: "SA",
        })
        .then((response) => {
          console.log()
          let res = response.body;
          if(res.errors){
            this.error=true
            this.errorMessage=res.errors[0].detail;
          }
          if(res.data)
          {
            this.$emit('childEvent',3)
          }
          console.log(res.data);
        });
    },
    
  },
};
</script>
<style>
</style>
  