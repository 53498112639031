<template>
  <div>
    <h5 class="text-center text-success"> Your QR Code is ready! </h5>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="row d-flex" style="justify-content: center;">
          <img src="../../../../../assets/images/dummy-qr.jpg" alt="" style="width: 250px; height: 250px;">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {IconCheckbox} from 'src/components/UIComponents'

  export default {
    components: {
      IconCheckbox
    },
    data() {
      return {
        model: {
          design: true,
          code: false,
          develop: false
        },
      }
    },
    methods: {
      validate() {
        this.$emit('on-validated', true, this.model)
        return Promise.resolve(true)
      }
    }
  }
</script>
<style>
</style>
