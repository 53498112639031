<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <card class="form-horizontal">

        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider name="OTP" rules="required|numeric" v-slot="{ passed, failed }">
              <fg-input type="number" :error="failed ? 'The OTP field is required' : null" :hasSuccess="passed"
                name="email" v-model="otp" placeholder="Eter OTP">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>


        <div slot="footer" class="text-center">
          <p-button type="info" native-type="submit" @click="submit">Verify OTP</p-button>
        </div>
      </card>
    </form>
  </ValidationObserver>
</template>
<script>
import { Card } from "src/components/UIComponents";
import { extend } from "vee-validate";
import { required, numeric, regex, confirmed } from "vee-validate/dist/rules";

extend("required", required);
extend("numeric", numeric);
extend("regex", regex);
extend("confirmed", confirmed);

export default {
  components: {
    Card
  },
  data() {
    return {
      otp: 0
    };
  },
  methods: {

    submit() {
      this.$http
        .post("user/verify_email_otp", {
          user_email: 'shahimellathodi@gmail.com',
          user_email_otp: this.otp
        })
        .then((response) => {
          alert('registration successfull')
          console.log(response)
          let res = response.body;
          if(res.errors){
            this.error=true
            this.errorMessage=res.errors[0].detail;
          }
          if(res.data)
          {
            alert('registered')
            this.$emit('childEvent',4)
          }

          console.log(res.data);
        });
    },
  },
};
</script>
<style></style>