<template>
  <div class="register-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page register-page section-image" filter-color="black">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 col-md-5 ml-auto">
                <info-section class="mt-5" type="primary" title="Marketing"
                  description="We've created the marketing campaign of the website. It was a very interesting collaboration."
                  icon="nc-icon nc-tv-2">
                </info-section>
                <info-section type="primary" title="Fully Coded in HTML5"
                  description="We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub."
                  icon="nc-icon nc-html5">
                </info-section>

                <info-section type="primary" title="Built Audience"
                  description="There is also a Fully Customizable CMS Admin Dashboard for this product."
                  icon="nc-icon nc-atom">
                </info-section>
              </div>
              <div class="col-lg-4 col-md-6 mr-auto">
                <card type="signup" class="text-center">
                  <p v-if="formNumber == 2">
                    Alredy have Account ?
                    <a href="#" @click="changeForm(1)">login In here</a>
                  </p>
                  <p v-if="formNumber == 1">
                    New User ?
                    <a href="#" @click="changeForm(2)">Register In here</a>
                  </p>
                  <div v-if="formNumber == 3" class="">


                    <p class="font-weight-bold">
                      We've sent an OTP to
                      your email for verification. Please check your inbox
                      shortly.
                    </p>

                    If you don't receive the OTP within a few minutes, please
                    ensure to check your spam folder.
                  </div>
                  <new-user v-if="formNumber == 2" @childEvent="changeForm(3)"></new-user>
                  <user-log-in v-if="formNumber == 1"></user-log-in>
                  <verify-otp @childEvent="changeForm(4)" v-if="formNumber == 3"></verify-otp>
                  <finish v-if="formNumber == 4"></finish>
                </card>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(static/img/background/jan-sendereks.jpg)"></div>
      </div>
    </div>
  </div>
</template>
<script>
import NewUser from "./UserProfile/SignUp/NewUser.vue";
import UserLogIn from "./UserProfile/LogIn/LogIn.vue";
import VerifyOtp from "./UserProfile/SignUp/VerifyOtp.vue";
import ProContent from "./UserProfile/SignUp/ProContent.vue";
import finish from "./UserProfile/SignUp/Finish.vue";
import { extend } from "vee-validate";
import { required, email, confirmed } from "vee-validate/dist/rules";
import AppNavbar from "./Layout/AppNavbar";
import AppFooter from "./Layout/AppFooter";
import {
  Card,
  Checkbox,
  Button,
  InfoSection,
} from "src/components/UIComponents";

export default {
  components: {
    VerifyOtp,
    NewUser,
    UserLogIn,
    ProContent,
    finish,
    Card,
    AppNavbar,
    AppFooter,
    InfoSection,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
  },
  data() {
    return {
      /** 1- For login 2- signUp 3- Verify OTP */
      formNumber: 1,
      registration_status: false,
    };
  },
  methods: {
    changeForm(n) {
      alert(n)
      this.formNumber = n
      /*
      if (this.formNumber == 1) this.formNumber = 2;
      else if ((this.formNumber == 3)) this.formNumber = 3;
      else this.formNumber = 1;
      alert(this.formNumber)
       */
      console.log(this.formNumber);
    },

    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style></style>
