export default [
  {
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    path: '/admin/overview'
  },
  {
    name:'Profile',
    icon:'nc-icon nc-circle-10',
    path: '/register'
  },
  
]
