<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <card class="form-horizontal">
        <h4 slot="header" class="card-title">Login</h4>



        <div class="row">
          <div class="col-sm-12">
            <div v-if="error==true"
              class="alert alert-alert alert-danger alert-dismissible fade show"
            >
              <span>{{ errorMessage }}</span>
            </div>
            <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The Email field is required' : null" :hasSuccess="passed"
                name="email" v-model="email" placeholder="E-mail">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider name="password" rules="required|min:6" v-slot="{ passed, failed }">
              <fg-input type="password" :error="failed ? 'Please enter valid password number' : null"
                :hasSuccess="passed" name="password" v-model="password" placeholder="Enter Password">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>

        <div slot="footer" class="text-center">
          <p-button type="info" native-type="submit">SinUp</p-button>
        </div>
      </card>
    </form>
  </ValidationObserver>
</template>
<script>
import { Card } from "src/components/UIComponents";
import { extend } from "vee-validate";
import { required, numeric, regex, confirmed } from "vee-validate/dist/rules";

extend("required", required);
extend("numeric", numeric);
extend("regex", regex);
extend("confirmed", confirmed);

export default {
  components: {
    Card,
  },
  data() {
    return {
      error:false,
      errorMessage:'',
      required: "",
      email: "shahimellathodi@gmail.com",
      number: "",
      password: "123456",
      url: "",
      idSource: "",
      idDestination: "",
    };
  },
  methods: {
    submit() {
      console.log('function triggered')
      this.$http.post('user/signin',
        {
          'user_email': this.email,
          'user_password': this.password
        })
        .then(response => {
          let res = response.body

          if (res.data) {
            let user = res.data.user_info;
            let token = res.data.token;
            this.$store.commit("LoginSuccess", token);
            this.$store.commit("setUserData", user);
            this.$router.push('/wizard')
          }
          else {
            this.error = true
            this.errorMessage=res.errors[0].detail;
          }
        })
    },
  },
};
</script>
<style></style>