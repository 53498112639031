<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <p class="mt-4"><b>Page Template</b>(Click on the template you like)</p>
      <div class="row">
        <div class="col-md-3 mb-2" v-for="(template, index) in templates" :key="index">
          <a href="" @click.prevent="handleClick(template)">
            <div class="select-template">
              <img :src="template.template_profile_url" alt="" style="object-fit: contain;">
            </div>
          </a>
        </div>
      </div>
      <h4 class="mt-5">Profile</h4>
      <div class="row d-flex profile_brand-logo">
        <div class="picture-container">
          <el-upload class="picture" action="https://jsonplaceholder.typicode.com/posts/" :on-change="handlePreview"
            :auto-upload="false" :show-file-list="false">
            <img v-if="imageUrl" src="../../../../../assets/images/profile-pic.jpg" class="picture-src">
          </el-upload>
          <h6 class="description">Profile photo</h6>
        </div>
        <div class="picture-container">
          <el-upload class="picture" action="https://jsonplaceholder.typicode.com/posts/" :on-change="handlePreview"
            :auto-upload="false" :show-file-list="false">
            <img v-if="imageUrl" src="../../../../../assets/images/dummy-brand.jpg" class="picture-src">
          </el-upload>
          <h6 class="description">Brand logo</h6>
        </div>
      </div>
      <label for="name">Name</label>
      <fg-input placeholder="Name" name="name">
      </fg-input>
      <div class="row">
        <div class="col-lg-6">
          <label for="name">Heading</label>
          <fg-input placeholder="Title" name="name">
          </fg-input>
        </div>
        <div class="col-lg-6">
          <label for="name">Sub Heading</label>
          <fg-input placeholder="Company" name="name">
          </fg-input>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { Upload } from 'element-ui';
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";


extend("required", required);
extend("email", email);

export default {
  components: {
    [Upload.name]: Upload
  },
  data() {
    return {
      templates: [],
      image: "",
      previewText: "blue",
      email: "shahimellathodi@gmail.com",
      imageUrl: 'src/assets/images/blank-profile-picture.webp'
    };
  },
  methods: {
   
    getTemplates() {
      this.$http
        .get("user/list_profile_template")
        .then((response) => {
          //console.log(response.body.data.data)
          let res = response.body.data.data
          for (let template in res) {
            this.templates.push(res[template])
          }
        })
    },
    handleClick(data) {
      this.$emit('custom-event', data);
    },
    handlePreview(file) {
      this.imageUrl.imageUrl = URL.createObjectURL(file.raw);
    },
    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit('on-validated', res, this.imageUrl)
        return res
      })
    }
  },
  beforeMount() {
    this.getTemplates()
  }
}
</script>
<style lang="scss" scoped>
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}

.select-template {
  background-color: #66615b;
  height: 200px;
  border-radius: 10px;
}

.select-template:hover {
  border: 2px solid black;
}

.profile_brand-logo {
  justify-content: space-around;
}
.form-control{
  margin-bottom: 10px;
}
</style>
