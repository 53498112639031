<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <h5 class="info-text"> Your Profile URL </h5>
        </div>
        <div class="col-sm-7">
          <ValidationProvider name="url" rules="email" v-slot="{ passed, failed }">
            <div class="d-flex" disabled>
              <fg-input value="profile.biztap.co" readonly="readonly">
              </fg-input>
              <fg-input name="email" 
                placeholder="Enter URL"  addon-left-icon="nc-icon nc-send">
              </fg-input>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import {Select, Option} from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    data() {
      return {
        streetName: "",
        streetNo: "",
        city: "",
        country: "",
        select: null,
        countryOptions: ["One", "Two", "Three", "Four", "Five", "Six"]
      };
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          if (!res) {
            return;
          }
          this.$emit("on-validated", res);
          return res;
        });
      }
    }

  }
</script>
<style>
</style>
