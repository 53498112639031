<template>
  <div class="register-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page register-page section-image" filter-color="black">
        <div class="content">
          <div class="container-fluid">
            <template>
              <div class="d-flex" style="justify-content:center">
                <button class="btn btn-dashboard" @click="goToDashboard">Go to dashboard 
                  <i class="fas fa-arrow-right" style="color: #ffffff;"></i>
                </button>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-md-10 mr-auto ml-auto">
                        <wizard @complete="wizardComplete">
                          <template slot="header">
                            <h3 class="card-title">Build your profile</h3>
                            <h3 class="description">This information will let us know more about you.</h3>
                          </template>

                          <wizard-tab :before-change="() => validateStep('step1')">
                            <template slot="label">
                              <i class="nc-icon nc-single-02"></i>
                              PROFILE
                            </template>
                            <first-step ref="step1" @custom-event="handleCustomEvent"></first-step>
                          </wizard-tab>

                          <wizard-tab :before-change="() => validateStep('step2')">
                            <template slot="label">
                              <i class="nc-icon nc-touch-id"></i>
                              Additional
                            </template>
                            <fourth-step ref="step2" @on-validated="onStepValidated"></fourth-step>
                          </wizard-tab>

                          <wizard-tab :before-change="() => validateStep('step3')">
                            <template slot="label">
                              <i class="nc-icon nc-pin-3"></i>
                              publish
                            </template>
                            <third-step ref="step3"></third-step>
                          </wizard-tab>

                          <wizard-tab :before-change="() => validateStep('step2')">
                            <template slot="label">
                              <i class="nc-icon nc-touch-id"></i>
                              QR CODE
                            </template>
                            <second-step ref="step2" @on-validated="onStepValidated"></second-step>
                          </wizard-tab>

                          

                          
                        </wizard>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 fixed-column custom-div d-none d-md-block">
                  <div class="choose_template container">
                    <iframe seamless src="https://profile.biztap.co/1" class="responsive-iframe" frameborder="0" width="768" height="1024"></iframe>
                  </div>

                </div>
              </div>
            </template>
          </div>
        </div>
        <br />
        <br />
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(static/img/background/jan-sendereks.jpg)"></div>
      </div>
    </div>
  </div>
</template>
<script>

import NewUser from "../Pages/UserProfile/SignUp/NewUser.vue";
import AppNavbar from "../Pages/Layout/AppNavbar.vue";
import AppFooter from "../Pages/Layout/AppFooter.vue";
import { Card } from "src/components/UIComponents";
import { Wizard, WizardTab } from 'src/components/UIComponents';
import FirstStep from '../ProfileWizard/Wizard/FirstStep.vue';
import SecondStep from '../ProfileWizard/Wizard/SecondStep.vue';
import ThirdStep from '../ProfileWizard/Wizard/ThirdStep.vue';
import FourthStep from '../ProfileWizard/Wizard/FourthStep.vue';
import Swal from 'sweetalert2';

/*



*/

export default {
  data() {
    return {
      TextPreview: '',
      wizardModel: {},
      iframe: {
        src: 'www.google.com'
      }
    }
  },
  components: {
    //TopNavbar,
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    Wizard,
    WizardTab,
    NewUser,
    Card,
    AppNavbar,
    AppFooter,
  },
  methods: {
    goToDashboard() {
        window.location.href = '/';
    },
    handleCustomEvent(data) {
      if (data == 1) {
        this.TextPreview = 'You selected first template'
      }
      else if (data == 2) {
        this.TextPreview = 'You selected seconed template'
      }
      else if (data == 3) {
        this.TextPreview = 'You selected third template'
      }
      else if (data == 4) {
        this.TextPreview = 'You selected fourth template'
      }
    },
    validateStep(ref) {
      return this.$refs[ref].validate()
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model }
    },
    wizardComplete() {
      Swal.fire({
        title: "Good job!",
        text: "You clicked the finish button!",
        type: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      });
    }
  }
  /*
  methods: {
    
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
  */
};

</script>
<style scoped>
.choose_template {
  background-color: white;
  height: 500px;
  border-radius: 10px;
}

.fixed-column {
  position: fixed;
  right: 100px;
  bottom: 45px;
}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.btn-dashboard {
    background-color: transparent;
    color: white;  
    border:1px solid white;
}
</style>
