<template>
    <ValidationObserver ref="form">
        <form @submit.prevent="validate">
            <div class="row justify-content-center">
                <div class="col-sm-12">
                    <div class="d-flex" style="justify-content: space-between;">
                        <h5 class="info-text"> Additional Information </h5>
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                @click="getComponents">
                                Add Component
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="#" v-for="component in components"
                                    @click.prevent="createInputField(component.component_label)">{{
            component.component_label }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-9">
                    <div class="row">
                        <label class="col-md-2 col-form-label">Name</label>
                        <div class="col-md-8">
                            <input placeholder="Name" id="defaultNameField">
                        </div>
                        <div class="col-md-2"></div>
                        <label class="col-md-2 col-form-label" id="defaultEmailField">Email</label>
                        <div class="col-md-8">
                            <fg-input placeholder="Email" id="defaultEmailField"></fg-input>
                        </div>
                        <div class="col-md-2"></div>

                        <label class="col-md-2 col-form-label">Contact Number</label>
                        <div class="col-md-8">
                            <fg-input placeholder="Contact Number"></fg-input>
                        </div>
                        <div class="col-md-2"></div>

                    </div>
                    <div class="row inputContainer" v-for="(input, index) in dynamicInputs" :key="index">
                        <label :for="input.name" class="col-md-2 col-form-label">{{ input.placeholder }}</label>
                        <div class="col-md-8">
                            <fg-input v-model="input.value" :placeholder="input.placeholder" :id="index"></fg-input>
                        </div>
                        <div class="col-md-2">
                            <a><i class="fa-solid fa-trash" style="color: #ff0000;" @click.prevent="deleteComponent(index)"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>
<script>
import { Select, Option } from 'element-ui'
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

export default {
    components: {

        [Select.name]: Select,
        [Option.name]: Option
    },
    data() {
        return {
            dynamicInputs: [],
            components: [],
        }
    },
    methods: {
        getComponents() {
            let name = document.getElementById("defaultNameField").value;
            console.log(name)
            
            this.$http
                .get("user/list_components")
                .then((response) => {
                    let res = response.body.data.data
                    this.components = res
                    console.log(this.components)
                }
                )
        },
        createInputField(componentName) {
            console.log('fn triggered')
            this.dynamicInputs.push({ placeholder: componentName });
        },
        deleteComponent(id){
            for( let index in this.dynamicInputs){
                if(id == index){
                    this.dynamicInputs.splice(index, 1)
                }
            }
        },
        validate() {
            return this.$refs.form.validate().then(res => {
                if (!res) {
                    return;
                }
                this.$emit("on-validated", res);
                return res;
            });
        }
    },
    beforeMount() {
        this.getComponents
    }

}
</script>
<style></style>