<template>
    <card class="form-horizontal">
    <div class="row">
        <div class="col-sm-12">
            <div class="d-flex justify-content-center align-items-center">
                <img src="../../../../../../assets/images/success-green-check-mark-icon.webp" alt="" class="img-thumbnail rounded-circle" style="width: 50px; height: 50px;">
            </div>
            <h3>Registration successfull</h3>
        </div>
    </div>
    </card>
</template>
<script>
  import { Card } from "src/components/UIComponents";

  export default {
    components: {
      Card
    },
    data() {
      
    },
  };
</script>

<style>

</style>
    