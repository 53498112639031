/*!

  =========================================================
  * Vue Paper Dashboard 2 PRO - V2.4.0
  =========================================================

  * Product Page: https://www.creative-tim.com/product/vue-paper-dashboard-2-pro
  * Available with purchase of license from https://www.creative-tim.com/product/vue-paper-dashboard-2-pro
  * Copyright 2019 Creative Tim (https://www.creative-tim.com)
  * License Creative Tim (https://www.creative-tim.com/license)

  =========================================================

*/



import Vue from 'vue'
import './pollyfills'
import VueRouter from 'vue-router'
// import VueRouterPrefetch from 'vue-router-prefetch'
import VueNotify from 'vue-notifyjs'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import App from './App.vue'

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'
import initProgress from './progressbar';

// router setup
import router from './routes/routes'
import store from "./store";
import VueResource from 'vue-resource'

// library imports

import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'

import sidebarLinks from './sidebarLinks'
import './registerServiceWorker'
// plugin setup
Vue.use(VueRouter)
Vue.use(GlobalDirectives)
Vue.use(VueResource)
// Vue.use(VueRouterPrefetch)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
Vue.use(SideBar, {sidebarLinks: sidebarLinks})
locale.use(lang)

// configure router
var vm = this;
router.beforeEach((to, from, next) => {
    let token = ''
    try {
      token = store.state.token
    } catch (err) {
        
    }
    if (token) {
        if (to.matched.some(record => record.meta.requiresAdmin)) {
          if (to.path == '/') {
              next('/register');
          } else {
              next()
          }
        } else {
            if (to.path == '/') {
                next('/register');
            } else {
                next()
            }
        }
    } else {
        if (to.path == '/') {
            next('/register');
            // let adres = JSON.parse(localStorage.getItem('adres'));
            // if (!adres) {
            //     next();
            // } else {
            //     next('/login');
            // }
        } else if (to.path == '/register') {
            next()
        } else {
            next('/register');
        }
    }
});

Vue.http.options.credentials = false
Vue.http.options.emulateJSON = true
Vue.http.options.root = 'https://console.biztap.riosisinnovations.com/'

Vue.http.interceptors.push((request, next) => {
    let token = store.state.token
    
    request.headers.set('Authorization', token)
    request.headers.set('Accept', 'application/json')
    return (response) => {
        if (response.status === 401) {
            router.push('/login')
        }
    }
})

console.log('token',store.state.token)
console.log('user',store.state.userData)

initProgress(router);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
})

