<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <!--
          <ul>
          <li>
            <a href="http://www.creative-tim.com">
              Creative Tim
            </a>
          </li>
          <li>
            <a href="http://blog.creative-tim.com">
              Blog
            </a>
          </li>
          <li>
            <a href="http://www.creative-tim.com/license">
              Licenses
            </a>
          </li>
        </ul>
        -->
      </nav>
      <div class="copyright pull-right">
        &copy; Coded with
            <i class="fa fa-heart heart"></i> by <a href="https://www.riosisinnovations.com" target="_blank" rel="noopener">Riosis Innovations Private Limited</a>.
      </div>
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
